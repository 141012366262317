<template>
  <div class="container mt-5">
    <!--Section: Content-->
    <section class="formation">
      <!-- Section heading -->
      <h2 class="text-center font-weight-bold">Formations Mobilité</h2>
      <!-- Section description -->
      <div class="mx-auto w-responsive mb-5">
        <div class="container mb-5">
          <!--Section: Content-->
          <section class="text-center">
            <!-- Section heading -->
            <p
              class="text-center argument font-weight-bold dark-grey-text pb-2 my-4"
            >
              les meilleurs offres
            </p>
            <!-- Section description -->
            <p class="text-center w-responsive mx-auto mb-5">
              Explorez nos
              <strong>formations en mobilité et passerelle</strong>, conçues
              pour vous permettre d'élargir vos compétences et d'ouvrir de
              nouvelles perspectives dans le
              <strong>secteur dynamique des transports et de la mobilité</strong
              >.
            </p>

            <!-- Grid row -->
            <div class="row">
              <!-- Grid column -->
              <div class="price-card col-lg-4 col-md-12 mb-4 fall">
                <!-- Pricing card -->
                <div class="card pricing-card white-text">
                  <!-- Price -->
                  <div class="aqua-gradient rounded-top">
                    <h3 class="option">MOBILITÉ<br />92 / 75 / 93</h3>
                  </div>

                  <!-- Features -->
                  <div class="card-body">
                    <p class="price">449<span>€</span></p>
                    <ul>
                      <li>
                        <p>14H / horaires à convenir</p>
                      </li>
                      <li>
                        <p></p>
                      </li>
                    </ul>
                    <div class="card-footer">
                      <router-link to="/formation-mobilite-taxi-92">
                        <button
                          name="button"
                          class="mb-3 mt-3 btn purple-gradient btn-rounded"
                        >
                          Détails &#187;
                        </button>
                      </router-link>
                    </div>
                  </div>
                  <!-- Features -->
                </div>

                <!-- Pricing card -->
              </div>
              <!-- Grid column -->

              <!-- Grid column -->
              <div class="price-card col-lg-4 col-md-6 mb-4 fall">
                <!-- Pricing card -->
                <div class="card pricing-card white-text">
                  <!-- Price -->
                  <div class="peach-gradient rounded-top">
                    <h3 class="option">Passerelle<br />taxi vers vtc</h3>
                  </div>

                  <!-- Features -->
                  <div class="card-body striped orange-striped card-background">
                    <p class="price">600<span>€</span></p>
                    <ul>
                      <li>
                        <p>
                          5 jours de formation<br />disponibilités à convenir
                        </p>
                      </li>
                      <li>
                        <p>
                          Véhicule mis à disposition<br />le jour de l'examen
                        </p>
                      </li>
                    </ul>
                    <div class="card-footer">
                      <router-link
                        to="/formation-mobilite-passerelle-taxi-vers-vtc"
                      >
                        <button
                          name="button"
                          class="mb-3 mt-3 btn purple-gradient btn-rounded"
                        >
                          Détails &#187;
                        </button>
                      </router-link>
                    </div>
                  </div>
                  <!-- Features -->
                </div>
                <!-- Pricing card -->
              </div>
              <!-- Grid column -->

              <!-- Grid column -->
              <div class="price-card col-lg-4 col-md-6 mb-4 fall">
                <!-- Pricing card -->
                <div class="card pricing-card white-text">
                  <!-- Price -->
                  <div class="purple-gradient rounded-top">
                    <h3 class="option">Passerelle<br />vtc vers taxi</h3>
                  </div>

                  <!-- Features -->
                  <div class="card-body striped purple-striped card-background">
                    <p class="price">600<span>€</span></p>
                    <ul>
                      <li>
                        <p>
                          5 jours de formation<br />disponibilités à convenir
                        </p>
                      </li>
                      <li>
                        <p>
                          Véhicule mis à disposition<br />le jour de l'examen
                        </p>
                      </li>
                    </ul>
                    <div class="card-footer">
                      <router-link
                        to="/formation-mobilite-passerelle-vtc-vers-taxi"
                      >
                        <button
                          name="button"
                          class="mb-3 mt-3 btn purple-gradient btn-rounded"
                        >
                          Détails &#187;
                        </button>
                      </router-link>
                    </div>
                  </div>
                  <!-- Features -->
                </div>
                <!-- Pricing card -->
              </div>
              <!-- Grid column -->
            </div>
            <!-- Grid row -->
          </section>
          <!--Section: Content-->
        </div>
      </div>
      <div class="hr-d"></div>
      <CallToContact
        title="Contactez nous"
        subTitle="L'equipe pédagogique de l'institut taxi s'occupe de vos démarches administratives."
      />
    </section>
    <!--Section: Content-->
  </div>
  <section class="container-fluid double-block">
    <div class="double-block__title">
      <h2>
        Votre centre de formation agréé pour<br />
        les taxi et vtc.
      </h2>
    </div>
    <div class="dark-grey-text double-block__articles">
      <div class="row align-items-center article">
        <div class="col-lg-5 slide-left text-center">
          <div class="view overlay rounded z-depth-2 mb-lg-0 mb-4">
            <img
              src="@/assets/pictures/passerelle.webp"
              alt="uber-order-phone"
              class="img-fluid"
              loading="lazy"
            />
          </div>
        </div>
        <div class="col-lg-7 slide-right">
          <h4 class="mb-3">
            Formations Mobilité :<br />
            Étendez votre Zone d'Activité avec Confiance
          </h4>
          <p>
            Au sein de l'Institut Taxi, nous proposons des
            <strong>formations</strong> spécifiques pour les
            <strong>chauffeurs de taxi</strong> souhaitant élargir leur
            d'activité aux départements de la
            <strong>Seine-Saint-Denis (93)</strong>,
            <strong>Paris (75)</strong> et <strong>l'Île-de-France</strong> et
            des <strong>Hauts-de-Seine (92)</strong>. Nos
            <strong>formations mobilité</strong> vous permettent d'acquérir les
            connaissances et compétences nécessaires pour opérer dans ces
            régions en toute confiance. Vous apprendrez les spécificités
            locales, les itinéraires clés, les réglementations propres à chaque
            département, ainsi que les meilleures pratiques pour satisfaire les
            besoins des clients dans ces zones. Avec notre soutien et nos
            ressources pédagogiques, vous pourrez développer votre activité et
            étendre votre offre de services en couvrant de nouveaux territoires.
            Rejoignez notre Institut Taxi et donnez une dimension supplémentaire
            à votre carrière de chauffeur.
          </p>
        </div>
      </div>
      <div class="hr-d"></div>
      <div class="row align-items-center article__left">
        <div class="col-lg-6 order-lg-2 slide-right text-center">
          <div class="view overlay rounded z-depth-2 mb-lg-0 mb-4">
            <img
              src="@/assets/pictures/man-vtc.webp"
              alt="man-drive-car"
              class="img-fluid"
              loading="lazy"
            />
          </div>
        </div>
        <div class="col-lg-6 order-lg-1 slide-left">
          <h4 class="mb-3">
            Formation Passerelle :<br />
            Élargissez vos Opportunités Professionnelles dans le Domaine du
            Transport de Personnes
          </h4>
          <p>
            À l'Institut Taxi, nous proposons des
            <strong>formations passerelles</strong> qui vous permettent de
            diversifier et d'élargir vos compétences professionnelles dans le
            domaine du <strong>transport de personnes</strong>. Que vous soyez
            chauffeur de taxi souhaitant
            <strong>devenir chauffeur VTC</strong> ou chauffeur VTC souhaitant
            <strong>devenir chauffeur de taxi</strong>, nos
            <strong>formations passerelles</strong> vous offrent une transition
            fluide vers votre nouvelle carrière. Vous bénéficierez d'un
            programme adapté à votre profil, qui met l'accent sur les
            spécificités du métier visé et vous permet d'acquérir les
            compétences requises. Nos formateurs expérimentés vous guideront
            tout au long de ce processus de transition, en vous fournissant les
            connaissances, les compétences et les outils nécessaires pour
            réussir dans votre nouveau rôle. Rejoignez-nous et ouvrez de
            nouvelles perspectives professionnelles grâce à nos
            <strong>formations passerelles</strong> de qualité.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CallToContact from "@/components/plugins/CallToContact.vue";
export default {
  name: "ServicesMobilite",
  components: { CallToContact },
  mounted() {
    import("@/assets/globalJs/global.js").then((module) => {
      const { observeElements } = module;
      observeElements(".slide-right", ".slide-left", ".fall");
    });
  },
};
</script>

<style lang="scss" scoped>
.formation {
  & h2 {
    text-align: center;
    background-color: $color-primary;
    color: $color-secondary;
    padding: 10px;
  }
  & .argument {
    font-family: "cunia";
    font-size: 1.3em;
  }
  .price-card {
    & .card {
      color: $color-text-light;
      background: #000000; /* fallback for old browsers */
      background: -webkit-linear-gradient(
        to bottom,
        #434343,
        #000000
      ); /* Chrome 10-25, Safari 5.1-6 */
      background: linear-gradient(
        to bottom,
        #434343,
        #000000
      ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

      & h3 {
        background-color: $color-primary;
        font-family: "quantum";
        color: $color-text-light;
        padding: 10px;
      }
      & .price {
        text-align: center;
        font-size: 2em;
        & span {
          color: $color-secondary;
        }
      }
      & .card-body {
        padding: 0px;
        & ul {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          list-style-type: none;
          padding: 0px;
          & li,
          p {
            text-align: center;
          }
        }
      }
      & .card-footer {
        background-color: $color-secondary;
        padding: 0px;
      }
    }
  }
}
.double-block {
  @media screen and (min-width: 992px) {
    padding: 20px 25px;
    margin: 0px 50px;
  }
  &__title {
    text-align: center;
    margin: 70px 5px 50px;
    & h2 {
      color: $color-primary;
    }
    @media screen and (min-width: 992px) {
      margin: 100px 0px;
    }
  }
  &__articles {
    margin: 0px 5px;
    .article {
      @media screen and (min-width: 992px) {
        margin: 0px 40px;
      }
      & h4 {
        color: $color-secondary;
      }
      &__left {
        & h4 {
          color: $color-primary;
        }
      }
    }
    & .div-btn {
      margin-top: 30px;
      transition: 0.3s;
      &__rightSide {
        @media screen and (max-width: 992px) {
          display: flex;
          justify-content: flex-end;
        }
      }
      & .btn {
        padding: 7px 10px;
        font-size: 0.8em;
        border: 0.3px outset;
        transition: 0.3s;
        &__first {
          background-color: $color-primary;
          color: $color-secondary;
        }
        &__second {
          background-color: $color-secondary;
          color: $color-primary;
        }
      }
      & :hover {
        scale: 96%;
      }
    }
  }
}
.slide-right.active {
  @include slide-right(1.2s);
}
.slide-left.active {
  @include slide-left(1.2s);
}
.fall.active {
  @include fall-down(1s);
}
</style>
