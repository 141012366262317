<template>
  <OnFooter />
  <footer class="footer pt-2">
    <div class="hr-dy"></div>
    <section class="text-white text-center">
      <div class="container-fluid footer__container">
        <!--Grid row-->
        <div class="footer__row col-lg-8">
          <!--Grid column-->
          <div class="brand mb-4 mb-md-0">
            <div class="brand__image col-lg-3">
              <img
                src="@/assets/brand/logo-square-transparent.webp"
                alt="logo web smart solution"
                class="img-fluid"
              />
            </div>
            <div class="brand__text col-lg-8">
              <h3>IttI</h3>
              <p>
                Centre de formation vtc et taxi à Gennevilliers, dans les
                Hauts-de-Seine (92), Vous propose ses formations Initiale,
                continue, mobilité et passerelle pour devenir chauffeur de taxi
                ou VTC. Rejoignez l'Institut Taxi et donnez un nouvel élan à
                votre carrière dans le secteur du transport de personnes.
              </p>
              <div class="hr-d separator"></div>
            </div>
          </div>

          <!--Grid column-->
          <div class="footer__links">
            <div class="mb-4 mb-md-0">
              <h4 class="text-uppercase">Formations taxi</h4>

              <ul class="list-unstyled mb-0">
                <li>
                  <router-link to="/formation-taxi-initiale"
                    >formation initiale taxi</router-link
                  >
                </li>
                <li>
                  <router-link to="/formation-taxi-continue"
                    >formation continue taxi</router-link
                  >
                </li>
                <li>
                  <router-link to="/formation-taxi-pratique-seule"
                    >examen pratique taxi</router-link
                  >
                </li>
                <li>
                  <router-link to="/formation-mobilite-passerelle-taxi-vers-vtc"
                    >passerelle<br />
                    taxi vers vtc</router-link
                  >
                </li>
              </ul>
            </div>
            <!--Grid column-->

            <!--Grid column-->
            <div class="mb-4 mb-md-0">
              <h4 class="text-uppercase">Formations vtc</h4>

              <ul class="list-unstyled mb-0">
                <li>
                  <router-link to="/formation-vtc-initiale" class="text-white"
                    >formation initiale vtc</router-link
                  >
                </li>
                <li>
                  <router-link to="/formation-vtc-continue" class="text-white"
                    >formation continue vtc</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/formation-vtc-pratique-seule"
                    class="text-white"
                    >examen pratique vtc</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/formation-mobilite-passerelle-vtc-vers-taxi"
                    class="text-white"
                    >passerelle<br />
                    vtc vers taxi</router-link
                  >
                </li>
              </ul>
            </div>

            <!--Grid column-->
            <div class="mb-4 mb-md-0">
              <h4 class="text-uppercase">taxi &#38; vtc</h4>

              <ul class="list-unstyled mb-0">
                <li>
                  <router-link
                    to="/formation-mobilite-taxi-92"
                    class="text-white"
                    >formation mobilité 92</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/formation-mobilite-taxi-92"
                    class="text-white"
                    >formation mobilité 75</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/formation-mobilite-taxi-92"
                    class="text-white"
                    >formation mobilité 93</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/formations-taxi-vtc?itemActif=formations-acceleree-vtc-taxi"
                    class="text-white"
                    >Accélérées<br />
                    semaine & weekend</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
          <!--Grid column-->
        </div>

        <div class="contact-footer col-lg-3">
          <SocialMedia />
          <div class="contact-card mt-4">
            <h4 class="jaapokki text-center mb-3">Nous Contacter</h4>
            <ul class="list-unstyled">
              <li>
                <router-link to="/contact">
                  <div>
                    <i class="fas fa-home"></i>
                    <p>
                      <strong
                        >10 rue de la Sablière,<br />
                        92230 Gennevilliers</strong
                      >
                    </p>
                  </div>
                </router-link>
              </li>

              <li>
                <a href="mailto:contact@malaweb.fr">
                  <div>
                    <i class="fas fa-envelope"></i>
                    <p>contact<span class="arobase">@</span>institut-taxi.fr</p>
                  </div>
                </a>
              </li>
              <li>
                <a href="tel:+33141112232">
                  <div>
                    <i class="fas fa-phone"></i>
                    <p>01 41 11 22 32</p>
                  </div>
                </a>
              </li>
              <li>
                <a href="tel:+33787185918">
                  <div>
                    <i class="fas fa-mobile"></i>
                    <p>07 87 18 59 18</p>
                  </div>
                </a>
              </li>
            </ul>
            <div class="qualiopi mx-auto">
              <img
                src="@/assets/pictures/qualiopi.webp"
                alt="qualiopi-logo"
                width="150"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Footer -->
    </section>
    <!-- Footer -->
    <div class="text-center text-white">
      <!-- Grid container -->
      <div class="container p-4 pb-0">
        <!-- Section: CTA -->
        <section class="">
          <p class="d-flex justify-content-center align-items-center my-4">
            <span class="me">Demandez votre devis</span>
            <button
              type="button"
              class="btn btn-outline-light btn-rounded btn-contact"
            >
              <a class="" href="/contact"> Contact </a>
            </button>
          </p>
        </section>
        <!-- Section: CTA -->
      </div>
      <!-- Grid container -->

      <!-- Copyright -->
      <div class="text-center p-3 copyright">
        <a class="text-white" href="/mentions"
          ><span><span>©</span> 2023 Copyright: INSTITUT TAXI <br /></span
        ></a>
        <a class="text-white" href="https://malaweb.fr/">Powered by Malaweb</a>
      </div>
      <!-- Copyright -->
    </div>
    <!-- Footer -->
  </footer>
</template>

<script>
import SocialMedia from "@/components/partials/SocialMedia.vue";
import OnFooter from "@/components/partials/OnFooter.vue";
export default {
  name: "FooterBase",
  components: { SocialMedia, OnFooter },
};
</script>

<style scoped lang="scss">
.footer {
  background-color: $color-primary;
  &__container {
    @media screen and (min-width: 992px) {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }
  &__row {
    padding: 5px;
    @media screen and (min-width: 992px) {
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
    }
    & .brand {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @media screen and (min-width: 992px) {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
      }
      &__image {
        img {
          max-width: 250px;
          margin: 0px 10px;
        }
      }
      &__text {
        & h3 {
          color: $color-secondary;
          font-family: "cunia";
          font-size: 3em;
          text-align: center;
          @media screen and (min-width: 992px) {
            text-align: left;
          }
        }
        & p {
          font-size: 0.8em;
          text-align: center;
          @media screen and (min-width: 992px) {
            font-size: 1em;
            text-align: left;
          }
        }
        & .separator {
          @media screen and (min-width: 992px) {
            margin: 50px 0px 50px 0px;
          }
        }
      }
    }
  }
  & .contact-footer {
    border-radius: 10px;
    @media screen and (max-width: 992px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  &__links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    @media screen and (min-width: 992px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
    }
    & h4 {
      color: $color-secondary;
    }
    & li {
      margin-bottom: 5px;
    }
    & a {
      color: $color-text-light;
    }
  }
  & .copyright {
    background-color: $color-secondary;
    & span {
      color: $color-text;
      font-weight: bolder;
      & span {
        font-size: 1.2em;
      }
    }
  }
  & .me {
    color: $color-secondary;
    margin-right: 5px;
  }
}
.contact-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & li {
    background-color: $color-primary;
    margin-bottom: 10px;
    padding: 3px 10px;
    border: 0.5px $color-text-light outset;
    border-radius: 10px;
    width: 100%;
    font-size: 0.6em;
    transition: 0.2s;
    &:hover {
      background-color: $color-primary;
      border: 0.5px $color-text-light inset;
      color: $color-text;
      & i {
        color: $color-secondary;
      }
      & p {
        color: $color-text-light;
      }
    }
    & .arobase {
      font-size: 1.5em;
    }
  }
  & div {
    display: flex;
    justify-content: start;
    align-items: center;
  }
  & a,
  router-link {
    color: rgba(230, 221, 221, 0.904);
    box-sizing: content;
    &:hover {
      color: $color-secondary;
    }
    & p {
      margin: 5px;
    }
  }
  & i {
    color: $color-secondary;
    font-size: 20px;
    margin-left: 10px;
    margin-right: 8px;
  }
}
.btn-contact {
  & a {
    color: $color-text-light;
  }
  &:hover {
    & a {
      color: $color-text;
    }
  }
}
.qualiopi {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
</style>
