<template>
  <div class="container pt-5 my-5">
    <!--Section: Content-->
    <section class="mx-md-5 dark-grey-text">
      <!-- Grid row -->
      <div class="row">
        <!-- Grid column -->
        <div class="col-md-12">
          <!-- Card -->
          <div class="card body-article">
            <button
              name="button"
              @click="circleBtn('formation-vtc')"
              class="retourBtn"
            >
              <span>&#171;</span> Retour formations
            </button>

            <OrderLesson lesson="Formation VTC Continue" />
            <!-- Card image -->
            <div class="container mt-5">
              <!--Section: Content-->
              <section class="mx-md-5">
                <!-- Grid row -->
                <div class="row">
                  <!-- Grid column -->
                  <article class="col-md-12">
                    <!-- Card -->
                    <div class="card cadre-img">
                      <!-- Card image -->
                      <div class="">
                        <img
                          class="card-img-top"
                          src="@/assets/pictures/formation-vtc-initiale.webp"
                          alt="time-laps-vtc"
                          loading="lazy"
                        />
                      </div>

                      <!-- Card content -->
                      <div class="card-body body-article__header text-center">
                        <!-- Title -->
                        <h1 class="font-weight-bold">FORMATION VTC</h1>
                        <!-- Social shares -->
                        <div class="advantage-thumb">
                          <div class="advantage-thumb__line">
                            <!-- Facebook -->
                            <p><span>Prix</span><br />1350€</p>
                            <!-- Twitter -->
                            <p><span>Durée</span><br />90h / 3 semaines</p>
                            <!-- Google+ -->
                            <p>
                              <span>Voiture de prêt</span><br />pour l'examen
                            </p>
                            <!-- Comments -->
                            <p><span>Financement</span><br />Eligible CPF</p>
                            <p><span>paiement</span><br />3x sans frais</p>
                          </div>
                        </div>
                        <!-- Social shares -->
                      </div>
                      <!-- Card content -->
                    </div>
                    <!-- Card -->

                    <!-- Excerpt -->
                    <div class="mt-5 body-article__content">
                      <h2>Formation vtc à {{ town.town }}</h2>
                      <p>
                        La <strong>formation initiale</strong> pour
                        <strong>devenir chauffeur VTC</strong> est une étape
                        cruciale pour acquérir les compétences nécessaires dans
                        ce domaine. Elle comprend un programme complet de cours
                        qui couvrent différents aspects tels que la
                        réglementation du
                        <strong
                          >transport public particulier de personnes</strong
                        >, la gestion, la sécurité routière, ainsi que les
                        compétences linguistiques en français et en anglais. La
                        <strong>formation</strong> est dispensée sur une durée
                        de 90 heures réparties sur 3 semaines, avec des horaires
                        flexibles incluant des sessions en journée, en soirée et
                        même le weekend. Un soutien financier est également
                        proposé avec la possibilité d'utiliser le
                        <strong>CPF</strong> (<strong
                          >Compte Personnel de Formation</strong
                        >) et la facilité de paiement en 3 fois sans frais. De
                        plus, une voiture est mise à disposition pour l'<strong
                          >examen pratique</strong
                        >, et en cas d'échec à l'<strong
                          >examen théorique</strong
                        >, une deuxième chance est accordée. Cette
                        <strong>formation</strong> vise à préparer les candidats
                        de manière complète et efficace pour leur future
                        <strong>carrière en tant que chauffeur VTC</strong>.
                      </p>
                      <div class="desk-card">
                        <div>
                          <h2>Épreuves théoriques vtc</h2>
                          <div class="card">
                            <div class="card-body">
                              <h3 class="card-subtitle card-title mb-2">
                                5 épreuves théoriques "Tronc commun"
                              </h3>

                              <ul class="list-group">
                                <li class="list-group-item">
                                  A – Réglementation du
                                  <strong
                                    >transport public particulier de
                                    personnes</strong
                                  >
                                  (coeff 3, note éliminatoire inférieure à 6/20)
                                </li>
                                <li class="list-group-item">
                                  B – Gestion (coeff 2, note éliminatoire
                                  inférieure à 6/20)
                                </li>
                                <li class="list-group-item">
                                  C - Sécurité routière (coeff 3, note
                                  éliminatoire inférieure à 6/20)
                                </li>
                                <li class="list-group-item">
                                  D – Capacité d'expression et de compréhension
                                  en langue française, sur la base d'un texte de
                                  15 à 20 lignes (coeff 2, note éliminatoire
                                  inférieure à 6/20)
                                </li>
                                <li class="list-group-item">
                                  E – Capacité d'expression et de compréhension
                                  en langue anglaise (coeff 1, note éliminatoire
                                  inférieure à 4/20)
                                </li>
                              </ul>

                              <h4 class="card-subtitle mt-3 mb-2">
                                2 épreuves spécifiques d'admissibilité
                              </h4>

                              <ul class="list-group">
                                <li class="list-group-item">
                                  F(V) Connaissance du territoire et la
                                  réglementation locale (coeff 3, note
                                  éliminatoire inférieure à 6/20)
                                </li>
                                <li class="list-group-item">
                                  G(V) Réglementation nationale de l'activité
                                  taxis ainsi que sur la gestion propre à
                                  l'activité (coeff 3, note éliminatoire
                                  inférieure à 6/20)
                                </li>
                              </ul>
                              <div class="card-footer text-muted">
                                <p class="card-text mt-3">
                                  Méthode d'évaluation : QCM et QRC
                                </p>
                                <p class="card-text">
                                  Seuil d'admissibilité : 10 / 20 (sans note
                                  éliminatoire)
                                </p>
                                <p class="card-text">
                                  Validité de 1 an pour 3 présentations à
                                  l'examen pratique.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <h2>Examen pratique vtc</h2>
                          <div class="card">
                            <div class="card-body">
                              <h3 class="card-title card-subtitle">
                                Épreuves pratiques d'admission
                              </h3>
                              <ul class="list-group">
                                <li class="list-group-item">
                                  OBJECTIF : évaluer la capacité du candidat à
                                  assurer, par sa conduite en circulation, la
                                  sécurité des passagers et des autres usagers
                                  de la route tout en proposant aux clients un
                                  service commercial de qualité.
                                </li>
                                <li class="list-group-item">
                                  Mise en situation pratique de réalisation
                                  d'une course de taxi.
                                </li>
                              </ul>
                              <ul class="list-group">
                                <li class="list-group-item">
                                  Seuil d'admission à l'examen : 12 / 20 à
                                  l'<strong>épreuve pratique</strong>.
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <h2>Examen t3p vtc</h2>
                      <p>
                        L'examen <strong>T3P</strong> (<strong
                          >Transport Public Particulier de Personnes</strong
                        >) est une étape essentielle pour
                        <strong
                          >obtenir la carte professionnelle de chauffeur
                          VTC</strong
                        >. Cet examen évalue les connaissances et compétences
                        nécessaires pour exercer cette profession. Il comprend
                        des <strong>épreuves théoriques</strong> portant sur la
                        réglementation, la gestion, la sécurité routière et les
                        compétences linguistiques. Les candidats doivent obtenir
                        une note minimale de 10/20 aux épreuves théoriques et
                        12/20 à l'épreuve pratique pour réussir. Une préparation
                        rigoureuse est recommandée pour réussir cet examen et
                        obtenir la
                        <strong>certification de chauffeur VTC</strong>.
                      </p>
                    </div>
                  </article>
                </div>
                <div class="hr"></div>
                <div class="text-white seo-landing">
                  <h2>Devenir VTC à {{ town.town }} :</h2>
                  <p>
                    Vous envisagez une carrière de VTC à {{ town.town }} ?
                    Institut Taxi, notre centre de
                    <strong>formation VTC</strong> et Taxi, est là pour vous
                    accompagner tout au long de votre parcours. Découvrez nos
                    <strong>formations VTC</strong> complètes, flexibles et
                    adaptées à vos besoins. Que vous soyez novice ou
                    expérimenté, nous avons une
                    <strong>formation VTC</strong> qui vous correspond. Nos
                    formateurs chevronnés vous aideront depuis la préparation de
                    l'examen jusqu'à l'obtention de votre
                    <strong>carte professionnelle VTC</strong>. Rejoignez-nous
                    pour transformer votre passion pour la conduite en une
                    carrière épanouissante.
                  </p>

                  <h2>Comment devenir VTC ?</h2>
                  <p>
                    Suivre une <strong>formation VTC</strong> est crucial pour
                    maximiser vos chances de succès à l'<strong
                      >examen VTC</strong
                    >. Voici les étapes pour
                    <strong>devenir VTC à {{ town.town }}</strong> :
                  </p>
                  <ul>
                    <li>
                      Ếtre titulaire du permis B depuis 3 ans minimum (ou 2 ans
                      si vous avez fait de la conduite accompagnée).
                    </li>
                    <li>
                      Participer à une <strong>formation VTC</strong>: vous
                      apportera les connaissances nécessaires sur la
                      réglementation du transport, la gestion d'entreprise, et
                      les techniques de conduite professionnelle. Notre
                      <strong>centre de formation VTC</strong> près de
                      {{ town.town }} vous propose un enseignement de qualité
                      adapté à votre emploi du temps.
                    </li>
                    <li>
                      Réussir l'<strong>examen théorique VTC</strong>: grâce à
                      notre <strong>formation VTC</strong> initiale qui couvre
                      des sujets complexes comme la sécurité routière, la
                      gestion financière, et le développement commercial. Vous
                      serez bien préparé pour réussir et progresser dans votre
                      carrière de <strong>chauffeur VTC</strong>.
                    </li>
                    <li>
                      Réussir l'<strong>examen pratique VTC</strong>: incluant
                      une épreuve de conduite professionnelle. Vous apprendrez à
                      maîtriser toutes les compétences pratiques nécessaires
                      pour être un <strong>chauffeur VTC</strong> exemplaire. Un
                      véhicule sera mis à votre disposition par l'Institut Taxi
                      pour l'<strong>examen pratique VTC</strong>.
                    </li>
                    <li>
                      La
                      <strong>carte professionnelle VTC</strong>: la demande ce
                      fait auprès de votre préfecture. Cette carte est
                      indispensable pour exercer la
                      <strong>profession de VTC</strong>. Notre centre de
                      formation vous accompagne dans toutes les démarches
                      administratives.
                    </li>
                    <li>
                      Le macaron <strong>VTC</strong>: la demande s'effectue au
                      registre des <strong>VTC à {{ town.town }}</strong
                      >. Ce macaron certifie votre qualification et aide les
                      clients à vous identifier comme un
                      <strong>chauffeur VTC</strong> autorisé. Nous vous guidons
                      dans l'obtention de ce macaron.
                    </li>
                    <li>
                      Choisir un <strong>véhicule VTC autorisé</strong> pour
                      exercer votre activité aprés la réussite de votre
                      formation pour
                      <strong>devenir vtc à {{ town.town }}</strong
                      >.
                    </li>
                    <li>
                      Choisir un mode de travail:
                      <strong>VTC indépendant</strong> ou
                      <strong>VTC salarié sur {{ town.town }}</strong
                      >. Vous pouvez gérer votre propre activité avec
                      flexibilité ou travailler pour une entreprise de transport
                      pour plus de sécurité et d'avantages (via des plateformes
                      comme <strong>Uber</strong>, <strong>Bolt</strong>) et
                      même cumulé les deux !
                    </li>
                  </ul>

                  <h2>
                    Informations utiles sur le métier de VTC à {{ town.town }}
                  </h2>
                  <h3 class="text-white text-left">VTC, c'est quoi ?</h3>
                  <p>
                    <strong
                      >VTC est l'acronyme de "Véhicule de Tourisme avec
                      Chauffeur"</strong
                    >. le métier de <strong>VTC</strong> exige une conduite
                    irréprochable ainsi qu'une présentation soignée et un bon
                    sens de la "communication client" pour transporter vos
                    passagers dans les meilleurs conditions.
                  </p>

                  <h3 class="text-white text-left">
                    Les véhicules VTC autorisés ?
                  </h3>
                  <p>
                    Pour <strong>devenir chauffeur VTC</strong>, votre véhicule
                    doit répondre aux critères suivants :
                  </p>
                  <ul>
                    <li>Avoir entre 4 et 9 places, chauffeur compris</li>
                    <li>
                      Ancienneté de 7 ans maximum (sauf si c'est une voiture de
                      collection)
                    </li>
                    <li>Minimum 4 portes</li>
                    <li>Dimensions minimales : 4,50 m x 1,70 m</li>
                    <li>
                      Puissance (nette) du moteur : supérieure ou égale à 84 kW
                    </li>
                  </ul>
                  <p>
                    Ces limitations ne s'appliquent pas aux véhicules hybrides
                    et électriques.
                  </p>
                  <p>
                    Exemples de <strong>véhicules VTC autorisé</strong> :
                    Renault Zoe, Peugeot e-208, Peugeot 508, Citroën C5, Toyota
                    C-HR, Kia Niro...
                  </p>

                  <h3 class="text-white text-left">
                    Le salaire d'un chauffeur VTC ?
                  </h3>
                  <p>
                    Le
                    <strong>revenu moyen d'un chauffeur VTC</strong>
                    varie entre 2500 et 5000€ par mois en fonction de plusieurs
                    critères: heures de travail, secteur géographique
                    d'activité. Mais également de votre mode de travail, si vous
                    utiliser des plateformes comme Uber, Bolt ou disposé de
                    votre propre réseau de
                    <strong>clients VTC sur {{ town.town }}</strong> et sa
                    région.
                  </p>
                  <h3 class="text-white text-left">
                    Différences entre VTC indépendant et VTC "Uber"
                  </h3>
                  <ul>
                    <li>
                      Tarification: Les
                      <strong>VTC Uber ont un tarif fixe</strong> avant la
                      course déterminé par la plateforme, contrairement aux
                      <strong>VTC indépendant</strong> qui applique son propre
                      tarif.
                    </li>
                    <li>
                      <strong>Carte pro VTC</strong>: La
                      <strong>carte professionnelle VTC</strong> est nécéssaire
                      dans les deux cas, en tant que
                      <strong>VTC indépendant</strong> ou
                      <strong>VTC "Uber"</strong>.
                    </li>
                  </ul>

                  <h2>Comment devenir chauffeur VTC à {{ town.town }} ?</h2>
                  <h3 class="text-white text-left">Formation initiale VTC</h3>
                  <p>Pour <strong>devenir VTC</strong>, vous devez:</p>
                  <ul>
                    <li>
                      Ếtre titulaire du permis B depuis 3 ans minimum (ou 2 ans
                      si vous avez fait de la conduite accompagnée).
                    </li>
                    <li>
                      Passer l'<strong>examen théorique VTC</strong> qui couvre
                      la
                      <strong
                        >réglementation des VTC dans le département de
                        {{ town.town }}</strong
                      >, la gestion d'entreprise, la sécurité routière, la
                      communication en français et anglais, le développement
                      commercial...
                    </li>
                    <li>
                      Passer L'<strong>examen pratique VTC</strong> consistant
                      en une mise en situation réelle de conduite avec des
                      examinateurs jouant le rôle de
                      <strong>clients VTC</strong>.
                    </li>
                    <li>Être détenteur de la carte professionnelle VTC.</li>
                    <li>
                      Avoir obtenue votre <strong>macaron VTC</strong> attestant
                      de votre inscription au <strong>registre des VTC</strong>.
                    </li>
                    <li>
                      Posséder un
                      <strong>vehicule VTC autorisé en france</strong>.
                    </li>
                  </ul>

                  <h2>Nos formations VTC à {{ town.town }}</h2>
                  <p>
                    L'Institut Taxi propose des formations adaptées à tous les
                    emplois du temps :
                  </p>
                  <ul>
                    <li><strong>Formation VTC initiale</strong> classique</li>
                    <li><strong>Formation VTC en soirée</strong></li>
                    <li><strong>Formation VTC le week-end</strong></li>
                    <li><strong>Formation VTC accélérée</strong></li>
                  </ul>

                  <h2>Pourquoi choisir l'Institut Taxi ?</h2>
                  <ul>
                    <li>Équipe pédagogique expérimentée</li>
                    <li>Matériel pédagogique complet</li>
                    <li>Examens blancs pour s'entraîner</li>
                    <li>Véhicule double commande pour l'examen pratique</li>
                  </ul>

                  <h2>Inscription et financement de votre formation VTC</h2>
                  <p>
                    Profitez de solutions de financement avantageuses pour votre
                    <strong>formation VTC</strong>, comme le CPF ou le
                    financement de Pôle Emploi, qui peuvent couvrir
                    partiellement ou totalement vos frais. Notre équipe experte
                    prend en charge toutes les démarches administratives
                    concernant votre <strong>formation vtc</strong>, de la
                    constitution du dossier CPF à l'obtention de votre
                    <strong>carte professionnelle VTC</strong> et du
                    <strong>macaron VTC</strong>. De plus, nous offrons des
                    options de paiement en trois fois sans frais pour faciliter
                    votre investissement dans votre avenir professionnel en tant
                    que <strong>chauffeur vtc</strong>.Alors n'hésitez plus, si
                    vous souhaitez <strong>devenir vtc à {{ town.town }}</strong
                    >, pour plus d'informations, un devis personnalisé ou des
                    conseils sur les meilleures options de financement,
                    contactez-nous par email, téléphone ou prenez rendez-vous
                    directement sur notre site, nous répondrons à toutes vos
                    questions sur nos
                    <strong>formations vtc à {{ town.town }}</strong
                    >.
                  </p>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <div class="hrFooter"></div>
</template>

<script>
import { useMeta } from "vue-meta";
import { toRef, watchEffect } from "vue";
import OrderLesson from "@/components/plugins/OrderLesson.vue";
import { mapGetters } from "vuex";
import { formatDate } from "@/assets/globalJs/global.js";

export default {
  components: {
    OrderLesson,
  },
  name: "TownRead",
  props: {
    town: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const townProp = toRef(props, "town");

    watchEffect(() => {
      if (townProp.value) {
        const sentenceBegin = "Découvrez notre formation VTC à ";
        const sentenceEnd =
          " pour devenir un chauffeur VTC professionnel qualifié. Apprenez les compétences essentielles en matière de réglementation, de service client, de gestion et de conduite professionnelle.";

        const metaDescription =
          sentenceBegin + townProp.value.content + sentenceEnd;

        useMeta({
          title: townProp.value.title,
          meta: [
            {
              name: "description",
              content: metaDescription,
            },
          ],
          link: [
            {
              rel: "canonical",
              href:
                "https://www.malaweb.fr/formation-vtc/" + townProp.value.slug,
            },
          ],
        });
      }
    });

    return {
      townData: townProp, // Utiliser un nom unique pour la clé retournée
    };
  },
  data() {
    return {
      showButton: false,
      scrollHeight: 500, // Hauteur de défilement souhaitée
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.handleScroll);
    });
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    formattedDate() {
      return formatDate(this.town.updatedAt);
    },
    ...mapGetters({
      Towns: "StateTowns",
      User: "StateUser",
      TownDataFromStore: "StateTown", // Renommer la clé pour éviter la duplication
    }),
  },
  methods: {
    circleBtn(itemActif) {
      this.$router.push({
        path: "/formations-taxi-vtc",
        query: { itemActif: itemActif },
      });
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    handleScroll() {
      if (window.pageYOffset > this.scrollHeight) {
        this.showButton = true; // Afficher le bouton lorsque la hauteur de défilement est atteinte
      } else {
        this.showButton = false; // Masquer le bouton sinon
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.body-article {
  & strong {
    font-style: none !important;
  }
  position: relative;
  @include boxShadowLight;
  background-color: $color-primary;
  margin-top: 20px;
  & .retourBtn {
    @include black-gradient;
    color: $color-secondary;
    border: none;
    border-radius: 2px;
    font-size: 0.7em;
    padding: 1px 5px;
    transition: 0.3s;
    width: fit-content;
    & span {
      font-size: 1.4em;
      color: $color-text-light;
    }
    &:hover {
      color: $color-text-light;
      & span {
        color: $color-secondary;
      }
    }
  }

  & .cadre-img {
    background-color: $color-secondary;
    & img {
      @media screen and (min-width: 992px) {
        max-height: 390px;
      }
    }
  }
  &__header {
    @include black-gradient;
    padding: 0px;
    & h1 {
      font-family: "quantum";
      color: $color-text-light;
      background-color: $color-secondary;
      padding: 7px;
    }
    & .advantage-thumb {
      padding: 5px 5px 8px;
      border-radius: 10px;
      @media screen and (min-width: 992px) {
        padding: 5px 5px 10px;
      }
      &__line {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        @media screen and (min-width: 992px) {
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
        }
        & p {
          @include black-gradient;
          padding: 5px 8px;
          border: 0.4px solid $color-secondary;
          border-radius: 5px;
          color: $color-text-light;
          font-size: 0.9em;
          font-style: normal !important;
          text-align: center;
          height: 100%;
          margin: 5px 20px;
          & span {
            color: $color-secondary;
          }
        }
      }
    }
  }
  &__content {
    color: $color-text-light;
    & h2 {
      color: $color-secondary;
      margin: 70px 0px 20px;
      text-align: center;
      @media screen and (min-width: 992px) {
        text-align: left;
      }
    }
    & h3,
    h4,
    h5 {
      color: $color-primary;
    }
    & p {
      font-size: 1em;
    }
  }
  & .hr {
    width: 100%;
    height: 1px;
    background-color: $color-secondary;
    margin: 10px auto 50px;
  }
}
h3,
h4,
h5 {
  color: $color-primary;
  text-align: center;
  margin-bottom: 15px;
}
.hrFooter {
  width: 70%;
  height: 1px;
  background-color: $color-text-grey;
  margin: 10px auto 100px;
}
.seo-landing {
  h2 {
    color: $color-secondary;
    text-align: left;
    margin-top: 45px;
  }
  h3 {
    text-align: left;
  }
}
</style>
