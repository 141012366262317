<template>
  <div class="container pt-5 my-5">
    <!--Section: Content-->
    <section class="mx-md-5 dark-grey-text">
      <!-- Grid row -->
      <div class="row">
        <!-- Grid column -->
        <div class="col-md-12">
          <!-- Card -->
          <div class="card body-article">
            <button
              name="button"
              @click="circleBtn('carte-pro-vtc-taxi')"
              class="retourBtn"
            >
              <span>&#171;</span> Retour formations
            </button>

            <OrderLesson lesson="Formation Mobilité" />
            <!-- Card image -->
            <div class="container mt-5">
              <!--Section: Content-->
              <section class="mx-md-5">
                <!-- Grid row -->
                <div class="row">
                  <!-- Grid column -->
                  <article class="col-md-12">
                    <!-- Card -->
                    <div class="card cadre-img">
                      <!-- Card image -->
                      <div class="">
                        <img
                          class="card-img-top"
                          src="@/assets/pictures/formation-mobilite.webp"
                          alt="Sample image"
                          loading="lazy"
                        />
                      </div>

                      <!-- Card content -->
                      <div class="card-body body-article__header text-center">
                        <!-- Title -->
                        <h1 class="font-weight-bold">
                          MOBILITÉ<br />
                          92 / 75 / 93
                        </h1>
                        <!-- Social shares -->
                        <div class="advantage-thumb">
                          <div class="advantage-thumb__line">
                            <!-- Facebook -->
                            <p><span>Prix</span><br />449€</p>
                            <!-- Twitter -->
                            <p>
                              <span>Durée</span><br />14h / horaires à convenir
                            </p>
                            <!-- Google+ -->
                          </div>
                        </div>
                        <!-- Social shares -->
                      </div>
                      <!-- Card content -->
                    </div>
                    <!-- Card -->

                    <!-- Excerpt -->
                    <div class="mt-5 body-article__content">
                      <h2 class="mb-4 text-center">formation Mobilité taxi</h2>
                      <h3 class="mb-2">
                        Ajouter un departement a sa carte professionnelle taxi
                      </h3>
                      <p>
                        Notre <strong>formation mobilité</strong> vous ouvre de
                        nouvelles perspectives en vous permettant d'<strong
                          >étendre votre activité de taxi à différents
                          départements</strong
                        >. Avec cette <strong>formation</strong>
                        spécialisée, vous pourrez obtenir l'extension de votre
                        <strong>carte professionnelle taxi</strong> dans des
                        départements spécifiques, vous offrant ainsi la
                        possibilité de desservir une clientèle plus large et de
                        développer votre entreprise.
                      </p>
                      <h3 class="mb-2">Mobilité taxi 92</h3>
                      <p>
                        Le <strong>département 92</strong> est une zone très
                        dynamique avec une forte demande de<strong
                          >services de taxi</strong
                        >. En suivant notre
                        <strong>formation mobilité pour le 92</strong>, vous
                        serez en mesure d'opérer dans de nombreuse villes des
                        <strong>Hauts-de-Seine</strong>. Vous apprendrez les
                        spécificités de la réglementation locale, les zones
                        géographiques clés et les principales attentes des
                        clients de ce département.
                      </p>
                      <h3 class="mb-2">Mobilité taxi 75</h3>
                      <p>
                        En ce qui concerne le <strong>département 75</strong>,
                        il représente le cœur de la capitale,
                        <strong>Paris</strong>, et constitue un marché très
                        attractif pour les <strong>chauffeurs de taxi</strong>.
                        Grâce à notre
                        <strong>formation mobilité pour le 75</strong>, vous
                        pourrez étendre votre activité à Paris et desservir les
                        arrondissements célèbres tels que le Marais, Montmartre,
                        les Champs-Élysées et bien d'autres. Vous acquerrez les
                        connaissances nécessaires sur la réglementation
                        spécifique à <strong>Paris</strong>, les itinéraires
                        courants et les particularités du transport de personnes
                        dans cette métropole animée.
                      </p>
                      <h3 class="mb-2">Mobilité taxi 93</h3>
                      <p>
                        Enfin, le <strong>département 93</strong> offre
                        également de nombreuses opportunités pour les
                        <strong>chauffeurs de taxi</strong>. En suivant notre
                        <strong>formation mobilité pour le 93</strong>, vous
                        pourrez travailler dans de nombreuse villes de la
                        <strong>Seine-Saint-Denis</strong> . Vous découvrirez
                        les particularités de la réglementation locale, les
                        zones d'intérêt majeures et les demandes spécifiques des
                        habitants et des visiteurs du département.
                      </p>

                      <p class="mt-5">
                        Que vous choisissiez d'étendre votre activité dans le
                        <strong>92</strong>, le <strong>75</strong> ou le
                        <strong>93</strong>, notre
                        <strong>formation mobilité</strong> vous donnera les
                        compétences nécessaires pour réussir dans ces
                        départements. Ne manquez pas cette occasion de
                        diversifier votre clientèle et d'élargir vos horizons
                        professionnels en
                        <strong
                          >ajoutant ces nouvelles zones à votre carte
                          taxi</strong
                        >.
                      </p>
                    </div>
                  </article>
                </div>
                <div class="hr"></div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <div class="hrFooter"></div>
</template>

<script>
import OrderLesson from "@/components/plugins/OrderLesson.vue";
import { useMeta } from "vue-meta";
export default {
  name: "RecuperationPoints",
  components: { OrderLesson },
  setup() {
    useMeta({
      title: "Formations Mobilité taxi",
      description: [
        {
          content:
            "Formation Mobilité Taxi (92, 75, 93) : Développez votre mobilité professionnelle avec notre formation spécialisée dans les départements des Hauts-de-Seine, Paris et la Seine-Saint-Denis. Obtenez les compétences nécessaires pour exercer l'activité de taxi dans ces zones dynamiques. Rejoignez-nous pour une formation pratique et complète",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.institut-taxi.fr/formation-mobilite-taxi-92",
        },
      ],
    });
  },
  data() {
    return {
      showButton: false,
      scrollHeight: 500, // Hauteur de défilement souhaitée
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.handleScroll);
    });
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    circleBtn(itemActif) {
      this.$router.push({
        path: "/formations-taxi-vtc",
        query: { itemActif: itemActif },
      });
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    handleScroll() {
      /*if (window.pageYOffset > this.scrollHeight) {
        this.showButton = false; // Masquer le bouton lorsque la hauteur de défilement est atteinte
      } else {
        this.showButton = true; // Afficher le bouton sinon
      }*/
      if (window.pageYOffset > this.scrollHeight) {
        this.showButton = true; // Afficher le bouton lorsque la hauteur de défilement est atteinte
      } else {
        this.showButton = false; // Masquer le bouton sinon
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.body-article {
  & strong {
    font-style: none !important;
  }
  position: relative;
  @include boxShadowLight;
  background-color: $color-primary;
  margin-top: 20px;
  & .retourBtn {
    @include black-gradient;
    color: $color-secondary;
    border: none;
    border-radius: 2px;
    font-size: 0.7em;
    padding: 1px 5px;
    transition: 0.3s;
    width: fit-content;
    & span {
      font-size: 1.4em;
      color: $color-text-light;
    }
    &:hover {
      color: $color-text-light;
      & span {
        color: $color-secondary;
      }
    }
  }

  & .cadre-img {
    background-color: $color-secondary;
    & img {
      @media screen and (min-width: 992px) {
        max-height: 390px;
      }
    }
  }
  &__header {
    @include black-gradient;
    padding: 0px;
    & h1 {
      font-family: "quantum";
      color: $color-text-light;
      background-color: $color-secondary;
      padding: 7px;
    }
    & .advantage-thumb {
      padding: 5px 5px 8px;
      border-radius: 10px;
      @media screen and (min-width: 992px) {
        padding: 5px 5px 10px;
      }
      &__line {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        @media screen and (min-width: 992px) {
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
        & p {
          @include black-gradient;
          padding: 5px 8px;
          border: 0.4px solid $color-secondary;
          border-radius: 5px;
          color: $color-text-light;
          font-size: 0.9em;
          font-style: normal !important;
          text-align: center;
          height: 100%;
          margin: 5px 20px;
          & span {
            color: $color-secondary;
          }
        }
      }
    }
  }
  &__content {
    color: $color-text-light;
    & h2 {
      color: $color-secondary;
      margin: 70px 0px 90px;
      text-align: center;
      @media screen and (min-width: 992px) {
        text-align: left;
      }
    }
    & h3,
    h4,
    h5 {
      color: $color-secondary;
      font-size: 1.2em;
      margin-top: 50px;
      &:first-of-type {
        font-size: 1em;
      }
    }
    & p {
      font-size: 1em;
    }
  }
  & .hr {
    width: 100%;
    height: 1px;
    background-color: $color-secondary;
    margin: 10px auto 50px;
  }
}
h3,
h4,
h5 {
  color: $color-secondary;
  text-align: left;
  margin-bottom: 15px;
}
.hrFooter {
  width: 70%;
  height: 1px;
  background-color: $color-text-grey;
  margin: 10px auto 100px;
}
</style>
